import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    "aria-hidden": _ctx.$window.navigator.onLine,
    class: "bg-red offline-banner"
  }, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('misc.offlineBanner')), 1)
  ], 8, _hoisted_1))
}