<template>
  <ion-alert
    :is-open="showNativeAppModal"
    :header="$t('nativeAppModal.title')"
    :message="$t('nativeAppModal.text')"
    css-class="my-custom-class"
    :buttons="buttons"
    @didDismiss="showNativeAppModal = false"
  >
  </ion-alert>
</template>

<script lang="ts">
import { IonAlert } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { defineComponent, ref } from 'vue';
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'NativeAppModal',
  components: {
    IonAlert
  },
  setup() {
    const { t } = useI18n();

    let showNativeAppModal = ref(false)
    const platform = Capacitor.getPlatform()
    setTimeout(() => {
      let lastShown = localStorage.getItem('nativeAppModalShown')
      let weekInMs = 7 * 24 * 60 * 60 * 1000

      if (
        platform !== 'ios' && 
        platform !== 'android' &&
        (lastShown === null || (Date.now() - weekInMs) > Number(lastShown)) // show once a week
      ) {
        showNativeAppModal.value = true
      }
    }, 10)

    let isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

    return {
      showNativeAppModal,
      buttons: [
        {
          text: `${t('nativeAppModal.buttons.goToStorePrefix')} ${isIOS ? 'App' : 'Play'} Store`,
          handler: () => {
            localStorage.setItem('nativeAppModalShown', Date.now().toString());

            let storeUrl = isIOS 
              ? 'https://apps.apple.com/de-de/app/bundeskunsthalle-bonn/id1627281181' 
              : 'https://play.google.com/store/apps/details?id=com.sunzinet.bundeskunsthalle&hl=de'

            // @ts-ignore
            let _mtm = window._mtm || [];
            // @ts-ignore
            _mtm.push({"event": "pwa-store-modal-goToStore"})
            
            setTimeout(() => {
              window.location.replace(storeUrl)
            }, 100)
          }
        },
        {
          text: t('nativeAppModal.buttons.continueWithWeb'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            localStorage.setItem('nativeAppModalShown', Date.now().toString());
            //track choice
            // @ts-ignore
            let _mtm = window._mtm || [];
            // @ts-ignore
            _mtm.push({"event": "pwa-store-modal-dismissed"})
          }
        }
      ]
    }
  }
});
</script>

<style lang="less">
.alert-button.sc-ion-alert-md {
    color: #000;
    font-weight: bold;
}
</style>