<template>
  <div :aria-hidden="$window.navigator.onLine" class="bg-red offline-banner">
    <p>{{ $t('misc.offlineBanner') }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonImg, IonButton } from '@ionic/vue';

export default defineComponent({
  name: 'OfflineBanner',
  components: {
    IonImg,
    IonButton
  },
  methods: {
    checkOnlineStatus() {
      (this as any).$bus.emit("initOnlineStatusListener", true);
    }
  }
});
</script>

<style lang="less" scoped>
.offline-banner {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  height: 30px;
  transition: top 700ms;
  background: #d60e3c;
  z-index: 1;
  text-align: center;
  color: #fff;

  p {
    margin: 0;
    line-height: 30px;
    font-size: 12px;
    font-weight: bold;
  }
}

.app-offline .offline-banner {
  top: 0;
}

</style>