<template>
  <ion-app>
    <div :class="{'app-offline': !isOnline}">
      <update-modal v-if="versionOutdated" />
      <offline-banner />
      <ion-router-outlet animated="true" />
    </div>
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, popoverController, useBackButton, useIonRouter } from '@ionic/vue'
import { defineComponent } from 'vue'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { Capacitor } from "@capacitor/core"
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications'
import { SplashScreen } from '@capacitor/splash-screen'
import { FCM } from '@capacitor-community/fcm'
import axios from 'axios'

// @ts-ignore
import { touchpointInteraction } from "@/service/api/user"
// @ts-ignore
import { localNotification } from '@/service/api/notifications.js'
// @ts-ignore
import OfflineBanner from '@/components/misc/offline-banner.vue'
// @ts-ignore
import UpdateModal from '@/components/misc/update-modal.vue'
// @ts-ignore
import ResponseData from "@/types/ResponseData";
// @ts-ignore
import Popover from '@/components/popover.vue'

import versionHelper from '@/helper/versionCompare'

declare global {
    interface Window {
        initMatomo: any;
    }
}

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    OfflineBanner,
    UpdateModal
  },
  data () {
    return {
      appToken: "",
      isOnline: true,
      lastActive: Date.now()/1000,
      versionOutdated: false,
      platform: Capacitor.getPlatform()
    }
  },
  computed: {
    appTokenData: {
      get: function () {
        return (this as any).appToken
      },
      set: function (id: string) {
        (this as any).appToken = id
      }
    }
  },
  methods: {
    handleDeepLink (url: string) {
      const slug = url.split('.de').pop();
      // We only push to the route if there is a slug present
      if (slug) {
        console.log('[Universal App Links] Opening deep link');
        console.log('[Universal App Links] New slug: ' + slug);
        console.log('[Universal App Links] Old slug: ' + (this as any).$router.currentRoute._value.slug);
        // if (vm.$router.currentRoute._value.slug !== slug)
        (this as any).$router.push({ path: '/tabs/home' });
        (this as any).$router.push({ path: slug });
      }
    },
    checkConsent () {
      const vm = (this as any)

      if (vm.$cc.getUserPreferences().accepted_categories.includes('analytical')) {
        !!vm.$window.initMatomo && vm.$window.initMatomo()
      }

      if (vm.$cc.getUserPreferences().accepted_categories.includes('functional')) {
        vm.initFCM();
      } else {
        vm.unsubscribeFromFcmTopic('public')
        vm.unsubscribeFromFcmTopic('private')
        vm.unsubscribeFromFcmTopic('public_de')
        vm.unsubscribeFromFcmTopic('public_en')
        vm.unsubscribeFromFcmTopic('private_de')
        vm.unsubscribeFromFcmTopic('private_en')
      }
    },
    initAppStateListener () {
      const vm = (this as any);

      App.addListener('appStateChange', async ({ isActive }) => {
        // On activation, first check if the app was open with a deeplink.
        // Required, because on iOS the `appUrlOpen` event is not triggered. :(
        // @see: https://github.com/ionic-team/capacitor/issues/1875
        // @see: https://github.com/ionic-team/capacitor/issues/2929
        if (isActive) {
          const openUrl = await App.getLaunchUrl();
          console.log('App opened with URL: ', openUrl);
          if (openUrl?.url) {
            console.log('[Universal App Links] appStateChange event with Launch URL')
            vm.handleDeepLink(openUrl.url);
            return;
          }
        }

        const currentTime = Date.now()/1000;

        if (isActive && (vm.lastActive <= (currentTime - 3600))) {
          if (vm.$router.currentRoute._value.name != "Home") {
            vm.$router.push({name: "Home"});
          } else {
            vm.$bus.emit("scrollToTop", true);
          }

          vm.$bus.emit("appStateChanged", isActive);
        } else if (!isActive) {
          vm.lastActive = Date.now()/1000;
          // if (vm.$router.currentRoute._value.name === "Audioguide-Item") {
          //   console.log('appStateChange event - redirect from Audioguide-Item to Audioguide-List', vm.$router.currentRoute._value)
          //   /* eslint-disable  @typescript-eslint/camelcase */
          //   vm.$router.push({name: "Audioguide-List", params: { typo3_id: vm.$router.currentRoute._value.params.typo3_id }})
          // }
        }
      });
    },
    initOnlineStatusListener () {
      const vm = (this as any);
      const updateOnlineStatus = () => {
        const online = navigator.onLine
        // only trigger handler when network change doesn't update within 1500ms
        setTimeout(() => {
          if (navigator.onLine === online) {
            if (navigator.onLine && !vm.isOnline) {
              setTimeout(() => {
                location.reload() // reload, when change from offline to online to avoid white page
              }, 750) // wait until offline banner animated out of view
            }
            vm.isOnline = navigator.onLine
            console.log("networkStatusChange", navigator.onLine)
            vm.$bus.emit("networkStatusChange", navigator.onLine)
          }
        }, 1500)
      }
      updateOnlineStatus()
      vm.$window.addEventListener('online',  updateOnlineStatus);
      vm.$window.addEventListener('offline', updateOnlineStatus);
    },
    initQrLoader () {
      const vm = (this as any);

      App.addListener("appUrlOpen", function (data: URLOpenListenerEvent) {
        console.log('appUrlOpen', data)
        if (data.url.indexOf('app.bundeskunsthalle.de') !== -1 || data.url.indexOf('pwa-staging.bukuha.sunzity.de') !== -1) {
          console.log('[Universal App Links] appUrlOpen event')
          vm.handleDeepLink(data.url);
        } else {
          const decodedURI = decodeURIComponent(data.url);
          const qr = vm.splitUrl(decodedURI);

          if (localStorage.getItem("jwt") !== null && vm.appTokenData) {
            console.log("appToken and jwt token available - check QR ID/type before sending");
            if (qr.params.has("id") && qr.params.has("type")) {
              console.log("QR ID/type available - send QR touchpoint interaction to middleware");
              vm.callTPInteraction(qr.id, "qr", vm.appTokenData);
            }
          } else {
            console.log("no jwt token");
          }

          switch (qr.type) {
            case "link":
              vm.callLink(qr.targetLink);
              break;
            case "modal":
              vm.callModal(qr.id);
              break;
          }
        }
      });
    },
    subscribeToFcmTopic (topic: string) {
      const vm = (this as any);
      if (Capacitor.isPluginAvailable('PushNotifications') && (vm.isPlatform("android") || vm.isPlatform("ios"))) {
        try {
          FCM.subscribeTo({topic: topic})
            .then((response: any) => console.log(`[FCM] Subscribed to: ${topic}`, JSON.stringify(response)))
            .catch((error: any) => console.log(`[FCM] Error subscribing to ${topic}`, JSON.stringify(error)));
        } catch(err) { console.error(err) }
      }
    },
    unsubscribeFromFcmTopic (topic: string) {
      const vm = (this as any);
      if (Capacitor.isPluginAvailable('PushNotifications') && (vm.isPlatform("android") || vm.isPlatform("ios"))) {
        try {
          FCM.unsubscribeFrom({topic: topic})
            .then((response: any) => console.log(`[FCM] Unsubscribed from: ${topic}`, JSON.stringify(response)))
            .catch((error: any) => console.log(`[FCM] Error unsubscribing from ${topic}`, JSON.stringify(error)));
        } catch(err) { console.error(err) }
      }
    },
    isPlatform (platform: string) {
      return (this as any).platform === platform
    },
    async initFCM () {
      const vm = (this as any);
      if (vm.isPlatform("android") || vm.isPlatform("ios")) {
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

        if (isPushNotificationsAvailable) {
          const lang = vm.$i18n.locale.split('_')[0]
          // Android 13 requires a new runtime permission check in order to receive push notifications. 
          // You are required to call checkPermissions() and requestPermissions() accordingly, when targeting SDK 33.
          let permStatus = await PushNotifications.checkPermissions()

          if (permStatus.receive === 'prompt') {
            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            permStatus = await PushNotifications.requestPermissions()
          }

          if (permStatus.receive !== 'granted') {
            console.log("Error on request permissions for PushNotifications")
            vm.initQrLoader()
          }

          // Register with Apple / Google to receive push via APNS/FCM
          await PushNotifications.register();
          
          vm.initQrLoader()

          PushNotifications.addListener('registration', (token: Token) => {
            console.log('Push registration success. Token: ' + token.value);
            vm.appTokenData = token.value;
            localStorage.setItem("appTokenData",token.value)
            vm.initQrLoader();
          });

          // Some issue with our setup and push will not work
          PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error));
            vm.appTokenData = "";
          });

          vm.subscribeToFcmTopic(`public_${lang}`)

          if (localStorage.getItem('jwt') !== null) {
            vm.subscribeToFcmTopic(`private_${lang}`)
          } else {
            vm.unsubscribeFromFcmTopic('private_de')
            vm.unsubscribeFromFcmTopic('private_en')
          }

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            console.log('Push Notification Received: ' + JSON.stringify(notification));
          });

          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed',  (notification: ActionPerformed) => {
            const message = notification.notification;
            console.log('Push Notification Action Performed: ' + JSON.stringify(notification.notification));
            if (message.data?.url?.length && typeof message.data.url !== "undefined") {
              vm.callLink(message.data.url);
            } else {
              vm.callLink("/home");
            }
            if (localStorage.getItem("jwt") !== null && vm.appTokenData) {
              if (message?.id?.length && typeof message.id !== "undefined")
                vm.callTPInteraction(message.id, "push", vm.appTokenData);
            } else {
              console.log("no jwt token");
            }
          });
        } else {
          vm.initQrLoader();
        }

        await SplashScreen.hide();
      }
    },
    callLink (link: string) {
      if (link.includes("http://") || link.includes("https://")) {
        Browser.open({ url: link || "" });
      } else {
        (this as any).$router.push({ path: "/tabs" + link });
      }
    },
    async callModal (id: string) {
      const popoverContent = await localNotification(id).then((response: ResponseData) => {
        return response.data
      }).catch((error: Error) => {
        console.log(JSON.stringify(error));
      });

      if (!popoverContent || (popoverContent.title !== "" && popoverContent.content !== "")) {
        const popover = await popoverController.create({
          component: Popover,
          componentProps: popoverContent,
          cssClass: 'qr-popover',
          translucent: true
        })
        await popover.present();

        const { role } = await popover.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
      }
    },
    callTPInteraction (id: string, type: string, token: string) {
      console.log('callTPInteraction ID: ', id);
      console.log('callTPInteraction type: ', type);
      console.log('callTPInteraction token: ', token);
      touchpointInteraction(id, type, token).then((response: ResponseData) => {
        console.log('touchpointInteraction response ' + JSON.stringify(response));
      }).catch((error: Error) => {
        console.log('touchpointInteraction error ' + JSON.stringify(error));
      });
    },
    splitUrl (url: string) {
      const urlSplitted = url.split("buku://")[1],
        slug = urlSplitted.split("?")[0],
        params = new URLSearchParams("?" + urlSplitted.split("?")[1]),
        id = params.get('id'),
        type = params.get('type'),
        targetLink = params.get('targetLink');

      return { slug: slug, params: params, id: id, type: type, targetLink: targetLink }
    },
    async initAppVersionCheck () {
      const currentAppVersion = process.env.VUE_APP_VERSION
      try {
        const { status, data } = await axios.get(`${process.env.VUE_APP_DIRECTUS_BASE_URL}/items/minAppVersion?fields=version_code`)
        if (status === 200 && data.data.version_code && data.data.version_code !== '' && data.data.version_code !== currentAppVersion) {
          const result = versionHelper.versionCompare(currentAppVersion, data.data.version_code)
          console.log('App version check', 'Current app version: ' + currentAppVersion)
          console.log('App version check', 'Min required app version: ' + data.data.version_code)
          console.log('App version check', 'Compare result: ' + result)
          if (result < 0) {
            console.log('App version check', 'App is outdated');
            (this as any).versionOutdated = true;
          }
        }
      } catch (e) {
        console.log('App version check failed', e)
      }     
    }
  },
  created () {
    const vm = (this as any);
    vm.initOnlineStatusListener();
    vm.initAppStateListener();
    vm.initAppVersionCheck();
  },
  mounted() {
    const vm = (this as any);

    console.log('[APP] Platform is: ' + vm.platform)

    vm.checkConsent()
    vm.$cc.on('consent-changed', () => {
        console.log('cookie consent changed, new user preferences:', vm.$cc.getUserPreferences())
        localStorage.setItem('checkedConsent', 'true')
        localStorage.setItem('consentSettings', JSON.stringify(vm.$cc.getUserPreferences()))
        vm.checkConsent() 
    })

    /**
     * A fucking workaround because Apple doesn't allow cookie usage
     * but the Cookieconsent Lib relies on them. Thus, we store the
     * settings in localStora (yay, thanks Apple this is okay) and restore
     * the settings on load.
     */
    if (vm.isPlatform("ios")) {
      if (localStorage.getItem('checkedConsent') !== null) {
        const settings = localStorage.getItem('consentSettings')
        if (settings !== null) {
          if (JSON.parse(settings).accepted_categories.includes('analytical')) {
            !!vm.$window.initMatomo && vm.$window.initMatomo()
          }
          if (JSON.parse(settings).accept_type === 'all') {
            vm.$cc.accept('all')
          }
          vm.$cc.hide()
        }
      }
    }

    vm.$bus.on('initOnlineStatusListener', (state: boolean) => {
      if (state) vm.initOnlineStatusListener()
    })

    const ionRouter = useIonRouter()
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack() && vm.isPlatform('android')) {
        App.exitApp()
      }
    })

    if (vm.$route.query.lang && vm.$route.query.lang !== vm.$i18n.locale && ['en_EN', 'de_DE'].includes(vm.$route.query.lang)) {
      vm.$i18n.locale = vm.$route.query.lang
    }
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      const vm = (this as any);
      console.log('Locale changed, old:', oldLocale)
      console.log('Locale changed, new:', newLocale)
      console.log('Locale changed, $i18n.locale:', vm.$i18n.locale)

      // save to locale storage
      localStorage.setItem("locale", newLocale);

      // update HTML lang attribute
      document.documentElement.setAttribute('lang', newLocale.split('_')[0])

      // inform the cookie consent instance
      vm.$cc.updateLanguage(newLocale.split('_')[0])

      // change push notification subscriptions
      vm.unsubscribeFromFcmTopic(`public_${oldLocale.split('_')[0]}`)
      vm.subscribeToFcmTopic(`public_${newLocale.split('_')[0]}`)
      if (localStorage.getItem('jwt') !== null) {
        vm.subscribeToFcmTopic(`private_${newLocale.split('_')[0]}`)
      }

      vm.$bus.emit("scrollToTop", { state: true, speed: 350 });
      vm.$bus.emit("changedLanguage", true);
    }
  }
});
</script>

<style lang="less">
.qr-popover {
  .list-md {
    background: linear-gradient(42.69deg, #F5E3E6 4.17%, #D9E4F5 89.72%) !important;

    h3 {
      font-family: 'Bonn BF' !important;
    }

    .item-lines-none {
      --background: linear-gradient(42.69deg, #F5E3E6 4.17%, #D9E4F5 89.72%) !important;
    }
  }
}

.plt-ios .force-hide-cc-on-ios #cc--main {
  display: none !important;
  z-index: -1 !important;
}

.plt-ios ion-app {
  inset-block-end: env(safe-area-inset-top) !important;
  inset-block-start: env(safe-area-inset-top) !important;
}
.plt-ios body {
  background-color: #000 !important;
}

ion-router-outlet {
  margin-top: 0;
  transition: margin-top 700ms;
}

.app-offline ion-router-outlet {
  margin-top: 15px;
}
</style>
